/**
 * @file Anything that can run on every page.
 */

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	import('../components/header/header').then(({ default: vHeader }) => {
		vHeader();
	});

	import('../components/mobile-menu/mobile-menu').then(
		({ default: vMobileMenu }) => {
			vMobileMenu();
		},
	);

	import('../components/client-stats/client-stats').then(
		({ default: vClientStats }) => {
			vClientStats();
		},
	);

	if (document.querySelector('.v-accordions')) {
		import('../components/accordions/accordions').then(
			({ default: vAccordions }) => {
				vAccordions();
			},
		);
	}

	/**
	 * Get scrollbar width.
	 * @returns {number} Scrollbar width.
	 */
	function getScrollbarWidth() {
		const outer = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.overflow = 'scroll';
		document.body.appendChild(outer);

		const inner = document.createElement('div');
		outer.appendChild(inner);

		const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

		outer.parentNode.removeChild(outer);

		return scrollbarWidth;
	}

	document.documentElement.style.setProperty(
		'--scrollbar-width',
		`${getScrollbarWidth()}px`,
	);

	const selects = document.querySelectorAll('select');
	selects.forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow = document.createElement('span');
		wrapper.classList.add('v-form__select');
		arrow.classList.add('v-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('v-form__select-field');
	});

	const radios = document.querySelectorAll('[type="radio"]');
	radios.forEach((radio) => {
		if (radio.classList.contains('v-form__radio')) {
			return;
		}

		const indicator = document.createElement('span');
		radio.classList.add('v-form__radio');
		indicator.classList.add('v-form__radio-indicator');
		radio.parentElement.insertBefore(indicator, radio.nextSibling);
	});

	const checkboxes = document.querySelectorAll('[type="checkbox"]');
	checkboxes.forEach((checkbox) => {
		if (checkbox.classList.contains('v-form__checkbox')) {
			return;
		}

		const indicator = document.createElement('span');
		checkbox.classList.add('v-form__checkbox');
		indicator.classList.add('v-form__checkbox-indicator');
		checkbox.parentElement.insertBefore(indicator, checkbox.nextSibling);
	});

	const tableWrappers = document.querySelectorAll('.v-table-wrapper');
	/**
	 * Toggle scroll indicator classes on table wrappers.
	 * @param {Element} tableWrapper - Table wrapper.
	 * @param {Element} tableInner - Scrollable inner table wrapper.
	 */
	const tableToggleClasses = (tableWrapper, tableInner) => {
		if (tableInner.scrollLeft > 0) {
			tableWrapper.classList.add('v-table-wrapper--scrollable-start');
		} else {
			tableWrapper.classList.remove('v-table-wrapper--scrollable-start');
		}

		if (
			tableInner.scrollLeft + tableInner.clientWidth <
			tableInner.scrollWidth
		) {
			tableWrapper.classList.add('v-table-wrapper--scrollable-end');
		} else {
			tableWrapper.classList.remove('v-table-wrapper--scrollable-end');
		}
	};
	tableWrappers.forEach((tableWrapper) => {
		const inner = tableWrapper.querySelector('.v-table-wrapper__inner');
		inner.addEventListener(
			'scroll',
			tableToggleClasses.bind(null, tableWrapper, inner),
			{ passive: true },
		);
		window.addEventListener(
			'resize',
			tableToggleClasses.bind(null, tableWrapper, inner),
			{ passive: true },
		);
		tableToggleClasses(tableWrapper, inner);
	});

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import('quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
